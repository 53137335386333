import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import { Box, Typography } from "@mui/material";
import React from "react";

const ComingSoon = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "70vh",
        textAlign: "center",
      }}
    >
      <HourglassEmptyIcon sx={{ fontSize: 80, color: "#1976d2", mb: 2 }} />
      <Typography variant="h4" gutterBottom>
        Coming Soon
      </Typography>
      <Typography variant="body1">
        This screen is under construction. Please check back later!
      </Typography>
    </Box>
  );
};

export default ComingSoon;
