import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Copyright from "./Copyright";

const Footer = () => {
  return (
    <Container maxWidth="sm">
      <Typography variant="body1">
        Life's greatest joy? Sharing it with those who matter most.
        #FamilyAdventures #MakingMemories
      </Typography>
      <Copyright />
    </Container>
  );
};

export default Footer;
