import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import hypTheme from "./HypparTheme";

const MainLayout = () => {
  return (
    <ThemeProvider theme={hypTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <CssBaseline />
        <Box>
          <Header></Header>
        </Box>
        <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="lg">
          <Outlet />
        </Container>
        <Box
          component="footer"
          sx={{
            py: 3,
            px: 2,
            mt: "auto",
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[200]
                : theme.palette.grey[800],
          }}
        >
          <Footer></Footer>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default MainLayout;
