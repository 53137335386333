import { Home } from "@mui/icons-material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import MainLayout from "./layout/MainLayout";
import ComingSoon from "./pages/Coming";
import JournalDetail from "./pages/JournalDetail";
import JournalList from "./pages/JournalList";
import Memories from "./pages/Memories";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route path="journal" element={<JournalList />} />
          <Route path="/journal/:id" element={<JournalDetail />} />
          <Route path="memories" element={<Memories />} />
          <Route path="coming" element={<ComingSoon />} />
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
