import MemoryListItem from "../features/memory/MemoryListItem";

const memories = [
  {
    code: "yashi_6th_birthday",
    title: "Little princess 6th birthday party",
    date: "13 April, 2024",
    location: "Home",
    thumbnail: "",
  },
  {
    code: "hyppar_house_warming",
    title: "Hyppar house warming",
    date: "17 February, 2024",
    location: "Home",
    thumbnail: "",
  },
];

const Memories = () => {
  const memoryItems = memories.map((memoryItem) => <MemoryListItem />);
  return <div>{memoryItems}</div>;
};

export default Memories;
