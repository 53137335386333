import { createTheme } from "@mui/material";
import { yellow } from "@mui/material/colors";

// FIXME - Create a favorite theme
const hypTheme = createTheme({
  palette: {
    primary: {
      main: yellow[500],
    },
  },
});

export default hypTheme;
